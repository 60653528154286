.passage-notes-viewer {
    height: 100%;
    overflow: auto;
}

.passage-notes-viewer__table-head {
    background-color: lightgray;
    position: sticky;
    top: 0;
    z-index: 1;
}

.passage-notes-viewer__table-head th {
    background-color: unset;
}

.passage-notes-viewer__note-time {
    color: #337ab7;
    cursor: pointer;
    text-decoration: underline;
}

.passage-notes-marker {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.passage-note-marker__note-marker {
    font-size: 18px;
    padding: 0;
}

.passage-notes-viewer__note-marker-label {
    min-width: 80px;
}

.passage-notes-viewer__visible-timestamp {
    direction: ltr;
}

.passage-notes-viewer__note-container {
    display: flex;
    gap: 8px;
    align-items: center;
}

.passage-notes-viewer__segment {
    cursor: pointer;
    text-decoration: underline;
    color: #337ab7;
}
