.task {
    margin-bottom: 5px;
}

.task-adder {
    color: lightgrey;
    font-size: 100%;
}

.task-adder:hover {
    color: #337ab7 !important;
}

.task-menu {
    opacity: 0;
    display: flex;
    align-items: center;
}

.task:hover .task-menu {
    opacity: 1;
    transition-duration: .5s;
}

.task-details-menu {
    margin-left: 10px;
    color: white;
    font-size: 80%;
    display: inline-block;
}

.project-plan {
    padding: 20px;
}

.project-plan:hover .task-details-menu {
    opacity: 1;
    transition-duration: .5s;
}

.task:hover .task-details-menu {
    color: #337ab7;
}

.task-details input {
    width: 600px;
}

.project-task-adder input {
    width: 600px;
}

.task-details-empty {
    display: inline-block;
}

.task-header {
    display: flex;
}

.blank-task-description {
    color: darkgray;
}

.task-view {
    display: flex;
    align-items: center;
}