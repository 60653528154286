.key-term-link {
    color: #337ab7;
    cursor: pointer;
    text-decoration: underline;
}

.terms-list-table-head {
    position: sticky;
    top: 0;
    background-color: lightgray;
}

.terms-list-table-head th {
    background-color: unset;
}