.review-response-choosers {
    display: flex;
    gap: 10px;
}

.review-dropdown {
    font-size: 10pt;
    background-image: none;
}

.review-dropdown-menu {
    overflow-y: auto;
    max-height: 450px;
}

.comments-table {
    border: 1px solid lightgray;
}

.comments-table tr {
    vertical-align: middle;
}

.comments-table-attribute-label {
    font-weight: 700;
}

.comments-table-header {
    background-color: lightgray;
}

.comments-table-header th {
    background-color: unset;
    vertical-align: middle;
}

.review-response-divider {
    border-top: 1px solid lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.get-responses-button {
    align-self: flex-end;
}
