.labeled-field-with-icon {
    display: grid;
    padding-bottom: 10px;
    grid-template-columns: [first] 33px [line2] calc(100% - 33px);
    align-items: center;
}

/* matches Multiselect */
.labeled-field-with-icon input {
    padding: 6px 10px;
    font-weight: 400;
    color: rgb(33, 37, 41);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
}

.labeled-field-with-icon .form-check input {
    padding: 6px;
}

.labeled-field-with-icon-label {
    grid-column-start: 2;
    color: #337ab7;
    font-weight: 500;
}

.labeled-field-with-icon-icon {
    font-size: 140%;
    padding-right: 28px;
    color: #337ab7;
}

body[dir='rtl'] .labeled-field-with-icon-icon {
    padding-right: 0;
    padding-left: 28px;
}
