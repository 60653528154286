.simple-audio-player {
    display: flex;
    align-items: center;
    direction: ltr;
}

.centered-controls {
    display: flex;
    align-items: center;
    margin: auto;
}

.published-audio-playback-button {
    font-size: 220%;
    margin: auto 5px;
}

.published-audio-change-time-button {
    font-size: 150%;
    margin: auto 5px;
}