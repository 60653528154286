.status-board-editor-project-name {
    padding-top: 3px;
}

.portion-selector-container {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.status-board {
    display: flex;
    background-color: rgb(53, 122, 183);
    overflow-x: auto;
    align-items: flex-start;
    height: 70vh;
}

@media screen and (max-height: 850px) {
    .status-board {
        height: 70vh;
    }
}

@media screen and (min-height: 850px) and (max-height: 1050px) {
    .status-board {
        height: 75vh;
    }
}

@media screen and (min-height: 1050px) {
    .status-board {
        height: 80vh;
    }
}

.difficulty-input {
    width: 60px;
}