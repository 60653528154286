.wavesurfer-lite {
    display: flex;
    direction: ltr; /* always ltr */
}

.wavesurfer-lite .wavesurfer  {
    flex-grow: 1;
    border: 1px solid lightgrey;
}

.wavesurfer-lite .controls span {
    margin-top: 50px;
}
