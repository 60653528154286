/* Portions taken from the example on https://github.com/tomkp/react-split-pane */

.Resizer {
    background: rgba(0, 0, 0, 1);
    opacity: .2;
    box-sizing: border-box;
    background-clip: padding-box;
}

.Resizer:hover {
    transition: all 1s ease;
}

.Resizer.vertical {
    width: 13px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 1);
    border-right: 5px solid rgba(0, 0, 0, 1);
}

.Resizer.horizontal {
    height: 13px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 1);
    border-bottom: 5px solid rgba(0, 0, 0, 1);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}