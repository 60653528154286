.member-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    border: 1px solid lightgrey;
    background: lightgrey;
    cursor: pointer;
}

.member-image-container-xl {
    width: 400px;
    height: 400px;
}

.member-image-container-lg {
    width: 64px;
    height: 64px;
}

.member-image-container-sm {
    width: 30px;
    height: 30px;
}

.member-image-container-extra-space {
    margin: 0 5px;
}

.member-icon {
    font-size: 1em;
    padding: 0 2px;
    cursor: default;
}

.member-display {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 2px;
}

.member-display-full {
    padding: 5px 0px;
}

.default-member-image-sm {
    font-size: 150%;
}

.default-member-image-lg {
    font-size: 350%;
}

.default-member-image-xl {
    font-size: 2000%;
}

.member-selector-dropdown {
    width: 15px;
    padding: 0px;
}

.member-selector-current-status {
    display: flex;
    align-items: center;
}

.member-selector-current-status.current-user {
    color: #337ab7;
}

.inline-flex-centered {
    display: inline-flex;
    align-items: center;
}
