.project-data-filter-toggle {
  background-image: none;
  font-size: 14px;
}

.project-data-filter-dropdown {
  overflow-y: auto;
  max-height: 450px;
}

.styled-dropdown-select-menu a.project-data-filter-item {
  padding: 8px 25px;
}

.styled-dropdown-select-menu .dropdown-header {
  color: #777;
  font-weight: 400;
  line-height: 1.42857143;
  font-size: 14px;
  padding: 3px 20px;
}

body[dir='rtl'] .styled-dropdown-select-menu .dropdown-header {
  text-align: right;
}