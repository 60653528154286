.stage-adder {
    color: lightgrey;
    font-size: 100%;
}

.stage-adder:hover {
    color: #337ab7 !important;
}

.stage {
    opacity: 1;
}

.project-plan-copy-button {
    color: lightgrey;
    font-size: 120% !important;
}

.project-plan-copy-button:hover {
    color: #337ab7;
}

.project-plan-warning-message {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.project-plan-warning-icon {
    font-size: 150% !important;
    color: orange;
    margin-right: 10px;
}