.error-list {
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    gap: 5px;
    background-color: #fca5a5;
    padding: 5px;
    color: #111827;
}

.error-list-error-icon {
    font-size: 130%;
    color: #111827;
}