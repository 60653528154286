.translation-top-container {
    display: flex;
    width: 100%;
    flex-grow: 1;
}

.translation-left-pane {
    margin-left: 10px;
    width: auto;
    overflow-y: hidden;
    overflow-x: hidden;
}

@media screen and (max-width: 900px) {
    .translation-left-pane {
        margin-left: 0px;
        width: 100%;
        border: 1px solid lightgrey;
        border-bottom: none;
    }
}

@media screen and (min-width: 901px) {
    .translation-left-pane:hover {
        overflow-y: auto;
        overflow-x: auto;
    }
}

.translation-middle-pane {
    /* flex-grow: 2; */
    width: 659px;
}

.translation-right-pane {
    flex-grow: 1;
    height: 850px;
    display: flex;
}

.segment-dialog-heading-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 7px;
}

.segment-dialog-heading {
    color: #337ab7;
    margin-right: 10px;
    font-weight: bold;
}

.segment-labels-editor {
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    padding: 20px;
    border-color: #337ab7;
}

.segment-labels-editor-buttons {
    /* not right way to do this, really want align right */
    margin-left: 120px;
}

.segment-label-text-editor {
    width: 83%;
}

.segment-position-editor {
    margin-top: 10px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    padding: 20px;
    border-color: #337ab7;
}

.segment-selector {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.segment-selector-text {
    font-size: 18px;
    font-weight: bold;
    vertical-align: 6%;
    margin: 0 5px;
}

.text-even {
    color: var(--even-segment-color);
}

.text-odd {
    color: var(--odd-segment-color);
}

.segment-selector {
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
}

.segment-selector-first-group {
    display: flex;
    align-items: center;
    flex: 0 1 33.3333%;
}

.segment-selector-middle-group {
    display: flex;
    align-items: center;
    flex: 0 1 33.3333%;
    justify-content: center;
}

.segment-selector-last-group {
    display: flex;
    align-items: center;
    flex: 0 1 33.3333%;
    justify-content: flex-end;
}

.segment-selector-patch-time {
    color: #da8383;
}

.segment-toolbar {
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 5px;
}

.segment-toolbar-first-group {
    direction: ltr;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 1 33.3333%;
}

body[dir='rtl'] .segment-toolbar-first-group {
    justify-content: right;
}

.segment-toolbar-middle-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 1 10%;
    justify-content: center;
}

.segment-toolbar-last-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 1 33.3333%;
    justify-content: flex-end;
}

.segment-toolbar-play-group {
    display: flex;
    align-items: center;
}

body[dir='rtl'] .segment-toolbar-play-group {
    direction: rtl;
}

.sl-play-bible-button {
    vertical-align: 4px;
    margin-left: 60px;
    color: teal;
}

.sl-select-bible-button {
    vertical-align: 4px;
    color: teal;
}

.sl-play-previous-draft-segment-button {
    margin-left: 81px;
    vertical-align: -16px;
}

.sl-select-previous-draft-segment-button {
    vertical-align: 4px;
    color: #337ab7;
}

.segment-cc-heading {
    margin-top: 10px;
}

.sl-dropdown {
    font-size: 10pt;
    background-image: none;
}

.image-thumbnail {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.copy-passage-loading-message-parent {
    display: flex;
    align-items: center;
}

.copy-passage-loading-message {
    margin-left: 10px;
}

.copy-passage-modal-section {
    padding-bottom: 10px;
}

.passage-key-terms-icon {
    color: darkorange;
}

.passage-notes-icon {
    color: green;
}

.right-pane-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
}

.right-pane-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
    display: flex;
    min-height: 42px;
}

.right-pane-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 4px;
    cursor: pointer;
}

.right-pane-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
}

.right-pane-tabs__tab-panel--selected {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
}

.split-button-wrapper {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    position: relative;
    top: 2px;
    margin-left: auto;
}

body[dir='rtl'] .split-button-wrapper {
    margin-left: 0;
    margin-right: auto;
}

.split-icon {
    width: 20px;
    height: 20px;
}

.patch-upload-file-button {
    margin-right: 5px;
    color: #337ab7;
}

.translation-right-pane-footer {
    align-items: center;
    color: #666666;
    direction: ltr;
    display: flex;
    flex-direction: column;
    font-size: 85%;
    padding-top: 20px;
    text-align: center;
    text-decoration: none;
}
