.video-timeline-play-button {
    font-size: 227%; 
    margin-left: 5px;
    margin-right: 3px;
    margin-top: -4px;
}

.video-timeline-play-beginning-button {
    width: 20px;
    height: 20px;
    margin-left: 7px;
    top: 2px;
}

.video-timeline-pause-button {
    font-size: 227%;
    margin-left: 5px;
    margin-right: 3px;
    margin-top: -4px;
}