.accordion.width {
    display: flex;
    width: 100%;
}

.accordion-card-content {
    min-width: 0;
    flex-grow: 1;
}

.accordion.width .accordion-card {
    flex-basis: 100%;
    transition: max-width 0.35s ease, flex-basis 0.35s ease;
}

.accordion.width .accordion-card.closed {
    flex-basis: 0;
}

.accordion-card.closed .accordion-card-content {
    display: none;
}

.accordion-card {
    position: relative;
    display: flex;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}

.accordion-card-header {
    padding: 0.875rem;
    background-color: rgba(0,0,0,.03);
}

.accordion.width .accordion-card .accordion-card-header {
    cursor: pointer;
    height: 100%;
    text-align: right;
}

.accordion.width .accordion-card .accordion-card-header h3 {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-size: 1rem;
    margin: 0;
    font-weight: 350;
}

body[lang='zh-CN'] .accordion.width .accordion-card .accordion-card-header h3 {
    transform: rotate(0deg);
}

.accordion.width .accordion-card .accordion-card-header[aria-disabled="true"] {
    pointer-events: none;
}
