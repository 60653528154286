:root {
    --amplify-fonts-default-variable: 'sans serif';
    --amplify-fonts-default-static: 'sans serif';

    --amplify-radii-small: 0;
    --amplify-radii-medium: 0;
    --amplify-radii-large: 0;
    --amplify-space-small: 0.875rem;
    --amplify-space-medium: 1rem;
    --amplify-space-large: 1.5rem;
    --amplify-border-widths-small: 2px;
    --amplify-border-widths-medium: 4px;
    --amplify-border-widths-large: 8px;
    --amplify-space-large: 1.5rem;
    --amplify-space-xl: 1.5rem;

    --amplify-components-authenticator-form-padding: 1.5rem;
    --amplify-components-authenticator-container-footer-padding-bottom: 0.75rem;
    --amplify-components-heading-1-font-size: 2.25rem;
    --amplify-components-heading-3-font-size: 1.5rem;
    --amplify-components-heading-4-font-size: 1.25rem;
    --amplify-components-heading-1-font-weight: 200;
    --amplify-components-button-primary-background-color: #337ab7;
    --amplify-components-button-primary-hover-background-color: #2e6da4;
    --amplify-components-button-font-size: 16px;
    --amplify-components-fieldcontrol-padding-block-start: 0.375rem;
    --amplify-components-fieldcontrol-padding-block-end: 0.375rem;
    --amplify-components-fieldcontrol-padding-inline-start: 1rem;
    --amplify-components-fieldcontrol-padding-inline-end: 1rem;
    --amplify-components-fieldcontrol-font-size: 1rem;
    --amplify-components-field-font-size: 1rem;
    --amplify-components-button-small-font-size: 0.75rem;
    --amplify-components-button-padding-block-start: 0.375rem;
    --amplify-components-button-padding-block-end: 0.375rem;
    --amplify-components-button-padding-inline-start: 1rem;
    --amplify-components-button-padding-inline-end: 1rem;
    --amplify-components-button-small-padding-block-start: 0.25rem;
    --amplify-components-button-small-padding-block-end: 0.25rem;
    --amplify-components-button-small-padding-inline-start: 0.75rem;
    --amplify-components-button-small-padding-inline-end: 0.75rem;
}

[data-amplify-authenticator] [data-amplify-container] {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

[data-amplify-router] {
    min-width: 320px;
    max-width: 320px;
}

.amplify-left-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.amplify-footer-container {
    grid-column-start: 1;
    grid-column-end: 3;
}
.amplify-left-container-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.amplify-login-header {
    position: relative;
    right: 15px;
    font-size: 24px;
    font-family: 'Helvetica';
    text-transform: none;
    letter-spacing: 0.01em;
    font-weight: 500;
    color: #337ab7;
}

.amplify-avtt-logo {
    position: relative;
    right: 15px;
    width: 140px;
    height: 52px;
}

.amplify-left-container-sub-header {
    position: relative;
    bottom: 15px;
    white-space: nowrap;
}

.amplify-avtt-headset {
    position: relative;
    right: 8px;
    width: 35px;
    height: 40px;
}

.amplify-input::placeholder {
    color: gray;
}

.amplify-text .fa-question-circle {
    color: #047d95;
}

.amplify-tabs-item[data-state='active'] {
    color: #2e6da4;
    border-color: #2e6da4;
}

[data-amplify-authenticator] [data-state='inactive'] {
    color: #99a6b2;
}

[data-amplify-authenticator] [data-state='inactive']:hover {
    color: #337ab7;
}

.amplify-button--small {
    font-size: 14px;
    color: #337ab7;
}

.amplify-button--small:hover {
    background-color: #fff;
    color: #2e6da4;
}

.amplify-text .fa-question-circle {
    color: #337ab7;
}

.amplify-checkbox__icon {
    background-color: #337ab7;
}

.amplify-button:hover {
    border-color: #337ab7;
}

@media screen and (max-width: 1024px) {
    .amplify-login-image {
        display: none;
    }

    .amplify-login-header {
        font-size: 20px;
    }

    .amplify-avtt-headset {
        width: 30px;
        height: 35px;
    }

    .amplify-left-container-header {
        margin-bottom: 12px;
    }

    [data-amplify-authenticator] [data-amplify-container] {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .amplify-left-container {
        position: relative;
        bottom: 0px;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 620px) {
    .amplify-left-container-sub-header {
        display: none;
    }

    .amplify-left-container-header {
        margin-bottom: 0px;
        margin-left: 35px;
    }
}
