.icon-spacer {
    margin-right: 15px;
}

body[dir='rtl'] .icon-spacer {
    margin-right: 0px;
    margin-left: 10px;
}

.project-review-general-config-display {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.text-input-with-label {
    display: flex;
    align-items: center;
}

.pr-text-modal-field {
    padding-bottom: 10px;
}

.project-review-modal-footer {
    display: flex;
}

.project-review-button {
    font-size: 130%;
}

.choices-table-action-column {
    margin-left: auto;
}

body[dir='rtl'] .choices-table-action-column {
    margin-left: 0px;
    margin-right: auto;
}

.profile-attribute-preferences {
    flex-grow: 1;
}

.profile-attribute-sortable-container {
    display: flex;
    flex-direction: column;
    transition: background-color 350ms ease;
    background-color: rgba(246, 246, 246, 1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 10px;
}

.profile-attribute-sortable-container-no-items {
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.profile-attribute-sortable-help-info {
    color: rgb(84, 84, 84);
}

.profile-attribute-sortable-container-item {
    background-color: white;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-attribute-sortable-container-item span.fa-grip-vertical {
    padding: 10px;
}

.profile-attribute-sortable-container-item span.fa-grip-vertical:hover {
    background-color: rgba(246, 246, 246, 1);
}

.profile-attribute-label {
    flex-basis: 200px;
}

.profile-attribute-options {
    flex-grow: 1;
}

.profile-attribute-buttons {
    display: flex;
    align-items: center;
}

.profile-attribute-edit-button {
    padding-right: 15px;
}

.attribute-choice-table.table {
    margin-bottom: 0px;
    border: 1px solid lightgray;
}

.profile-response-type {
    display: flex;
    align-items: center;
}

.profile-attributes-table {
    display: flex;
}

.no-padding {
    padding: 0px;
}

.review-project-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    min-height: 40px;
    justify-content: flex-start;
    column-gap: 25px;
    row-gap: 10px;
    margin-bottom: 15px;
}

.review-project-link-container {
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    gap: 8px;
}

.review-project-link-icon {
    font-size: 130%;
}

.review-project-link-text {
    color: #777;
}

.review-project-toggle-switch {
    display: flex;
    gap: 10px;
    min-height: 40px;
    align-items: center;
}

.review-project-qrcode-modal-button {
    font-size: 200%;
}

.review-project-qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-project-qrcode-modal-footer-icons {
    font-size: 200%;
    position: relative;
    top: 4px;
    margin-left: 10px;
}

.review-project-download-button {
    color: #337ab7;
}

.general-tab-section {
    padding-bottom: 10px;
}

.general-questions-text-editor {
    border: solid lightgray 1px;
    padding: 8px;
    width: 100%;
    height: 100px;
    overflow-y: auto;
    resize: none;
}

.audio-player-recorder-wrapper {
    border: 1px solid lightgray;
    padding: 8px;
    padding-top: 3px;
}

.general-question-text-area-wrapper {
    padding-bottom: 10px;
}

.general-question-submitting {
    display: flex;
    align-items: center;
}

.general-question-submitting-message {
    margin-left: 10px;
}

.project-review-button-row {
    display: flex;
    align-items: center;
}

.general-question-title {
    flex-basis: 200px;
}

.general-question-audio {
    flex-grow: 1;
}

.general-question-buttons {
    display: flex;
    align-items: center;
}

.general-question-edit-button {
    padding-right: 15px;
}

.general-question-sortable-container-item {
    background-color: white;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.general-question-sortable-container-item span.fa-grip-vertical {
    padding: 10px;
}

.general-question-sortable-container-item span.fa-grip-vertical:hover {
    background-color: rgba(246, 246, 246, 1);
}
