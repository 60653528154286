.reference-input {
    display: flex;
    height: 30px;
    align-items: center;
    gap: 10px;
}

.reference-input-searchbox {
    display: inline-block;
}

.reference-input-goto-reference-icon {
    font-size: 15pt;
    margin-right: 9px;
}

body[dir='rtl'] .reference-input-goto-reference-icon {
    margin-right: 0px;
    margin-left: 9px;
}

.reference-input-error {
    border-right: 8px red solid;
}

body[dir='rtl'] .reference-input-error {
    border-right: none;
    border-left: 8px red solid;
}