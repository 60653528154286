.image-references {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.image-references-toolbar {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
    flex-wrap: wrap;
}

.image-area {
    overflow-y: auto;
}

.image-area-images {
    margin-top: 8px;
    margin-left: 5px;
}

.image-area-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    gap: 10px;
}

.image-references-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-search-edit-button {
    position: absolute;
    top: 0px;
    left: 117px;
    font-size: 150%;
    color: #337ab7;
    z-index: 1;
    background-color: white;
    opacity: 0;
}

.image-search-item:hover .image-search-edit-button {
    opacity: 1;
    transition-duration: 0.5s;
}

@media screen and (any-hover: hover) {
    .image-container:hover {
        filter: brightness(0.5);
        transition-duration: 0.2s;
    }

    .image-button:focus .image-container {
        filter: brightness(0.5);
        transition-duration: 0.2s;
    }
}

.image-search-item {
    height: 100px;
    position: relative;
}

.image-container {
    background-color: lightgray;
    border: 1px solid gray;
}

.image-storyboard {
    border: 3px solid blue;
    padding: 2px;
}

.image-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.image-viewer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
}

.image-viewer-toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
}

.image-viewer-toolbar-start {
    flex-basis: 0;
}

.image-viewer-toolbar-middle {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-viewer-toolbar-end {
    flex-basis: 0;
    justify-content: right;
}

.image-upload-viewer-container {
    flex-grow: 1;
    background-color: lightgray;
    width: 300px;
    height: 200px;
    border: 1px solid lightgray;
}

.image-viewer-container {
    background-color: lightgray;
    height: 400px;
    border: 1px solid lightgray;
}

.image-upload-navigation {
    margin-top: 10px;
}

.image-viewer-title,
.image-viewer-citation,
.image-viewer-references,
.image-viewer-description,
.image-edit-text-editor,
.image-edit-references,
.image-edit-willing-to-share {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.image-viewer-copyright {
    text-align: center;
    color: #666666;
    font-size: 85%;
    padding-top: 1rem;
}

.image-upload {
    height: 100%;
    width: 100%;
}

.image-list-viewer {
    height: 100%;
    width: 100%;
}

.image-upload-title {
    color: #337ab7;
    font-weight: bold;
    vertical-align: 6%;
    margin-left: 5px;
    margin-right: 5px;
}

.image-upload-required-icon {
    color: orange;
    font-size: 150%;
}

.image-pane-header-label {
    color: #337ab7;
    font-weight: bold;
}

.image-pane-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.image-storyboard-close-button {
    font-size: 175%;
}

.image-approval-close-button {
    font-size: 175%;
}

.image-storyboard-viewer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.image-approval-viewer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.project-image-thumbnail {
    border: 3px dotted blue;
    padding: 1px;
}

.shared-project-image-thumbnail {
    border: 3px solid blue;
    padding: 1px;
}

.loading-area {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-approval {
    margin-left: 3px;
}

.image-thumbnail-editing {
    height: 200px;
}

.image-viewer-title {
    font-weight: bold;
    font-size: 120%;
    margin-top: 10px;
    margin-bottom: 8px;
}

.images-area {
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.image-metadata-input {
    width: 400px;
    vertical-align: 4px;
}

.image-metadata-editor {
    margin-top: 10px;
    border-top: 1px solid lightgrey;
    padding-top: 5px;
}

.image-metadata-editor-box {
    padding-top: 8px;
}

.image-metadata-editor-buttons {
    display: flex;
    margin-top: 10px;
    border-top: 1px solid lightgrey;
    padding-top: 5px;
}

.image-metadata-editor-box-references,
.reference-input {
    display: inline-flex;
}

.image-metadata-editor-buttons,
.sl-ok-edit-segment-labels-button {
    top: 0px;
}
