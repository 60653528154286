.column-container {
    margin: 4px;
    border: 1px solid lightgrey;
    background-color: rgb(227, 227, 227);
    border-radius: 2px;
    width: 150px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
}

.task-list {
    padding: 4px;
    min-height: 100px;
}

.task-column-header {
    padding: 4px;
}

.task-column-header .task-details {
    font-size: .9rem;
}