/* Styles for search box */
.search-box-down-arrow {
    font-size: 1.75em;
}

.search-box-container {
    display: flex;
    align-items: center;
    position: relative;
}

.search-box__suggestions-container {
    display: none;
}

.search-box__suggestions-container--open {
    display: block;
    position: absolute;
    width: 174px;
    border: 1px solid #cccccc;
    max-height: 150px;
    overflow-y: auto;
    background-color: white;
    z-index: 10;
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 100%;
    left: 0;
}

.search-box__input-wrapper {
    position: relative;
}

.search-box__input--with-validity {
    padding: 2px 29px 2px 1px;
    width: 175px;
}

.search-box__input--plain {
    padding: 2px 1px 2px 1px;
    width: 175px;
}

.search-box__suggestion {
    cursor: pointer;
    padding: 2px;
}

.search-box__suggestion:hover {
    background-color: #ddd;
}

.search-box__suggestion--highlighted {
    background-color: #ddd;
}

.search-box__invalid-icon {
    font-size: 1.5em;
    color: #953b3b;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
