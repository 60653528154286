.generic-icon {
    font-size: 3em;
    padding: 5px;
}

.sl-fa-button {
    font-size: 3em;
    padding: 5px;
    cursor: pointer;
}

.sl-button-disabled {
    color: darkgray !important;
    cursor: default;
}

.sl-image-button-disabled {
    filter: opacity(0.3);
}

.sl-stop-button {
    cursor: pointer;
    position: relative;
    vertical-align: baseline;
}

.clipboard-button {
    font-size: 160%;
}

.verse-reference-delete-button {
    margin-left: 4px;
    margin-right: 15px;
    font-size: 150%;
}

.segment-stop-button {
    margin-right: 4px;
}

.sl-play-button {
    position: relative;
    vertical-align: baseline;
}

.note-marker {
    position: relative;
    vertical-align: baseline;
}

.sl-segment-toolbar-button {
    font-size: 150%;
}

.segment-references-button {
    font-size: 175%;
    margin-right: 5px;
}

.sl-pause-button {
    cursor: pointer;
    position: relative;
    vertical-align: baseline;
}

.video-toolbar-button {
    font-size: 220%;
    margin-right: 5px;
}

.main-upload-file-button {
    color: #337ab7;
}

.sl-record-button {
    padding-left: 3px;
    padding-right: 4px;
    color: #953b3b;
}

.sl-record-selection-button {
    color: #da8383;
    background-color: lightgray;
}

.sl-record-patch-button {
    position: relative;
    cursor: pointer;
    margin-right: 5px;
    color: #da8383;
}

.sl-create-note-button {
    color: green;
}

.sl-create-passage-segment {
    margin-right: 5px;
    padding: 5px;
    height: 35px;
}

.segment-resource-text-area:hover .opaque-on-hover {
    opacity: 1;
    transition-duration: 1s;
}

.sl-ok-edit-segment-labels-button {
    color: green;
    font-size: 250%;
    vertical-align: baseline;
    top: 5px;
    cursor: pointer;
    position: relative;
}

.sl-cancel-edit-segment-labels-button {
    font-size: 212%;
    margin: 0 5px;
    vertical-align: 4px;
    top: 3px;
    cursor: pointer;
    position: relative;
}

#dropdown-select-segment {
    top: -18px;
    background-image: none;
    border: none;
    box-shadow: none;
}

.sl-adjust-current-time-button {
    width: 25px;
    padding: 5px;
}

.sl-vertical-adjust-current-time-buttons {
    display: inline-block;
    margin-right: 6px;
}

.sl-vertical-adjust-current-time-button {
    width: 12px;
}

.delete-patch-video-button {
    position: relative;
    color: #da8383;
    margin-right: 5px;
}

.sl-previous-segment-button {
    cursor: pointer;
    color: #337ab7;
}

.sl-next-segment-button {
    cursor: pointer;
    color: #337ab7;
}

.append-segment-button {
    margin: 0 15px;
    color: #337ab7;
}

.sl-delete-button {
    margin: 0 5px;
    position: relative;
    top: -6px;
    font-size: 2em;
    color: indianred;
}

.clickable {
    cursor: pointer;
}

.small-note-marker {
    font-size: 150%;
}

.note-bar-marker {
    padding: 0px;
}

.unresolved-note {
    color: gray;
}

.delete-video-button {
    font-size: 1.5em;
}

.video-toolbar-notification {
    font-size: 120%;
    margin-left: 4px;
}

.lock-resolve-button {
    font-size: 150%;
}

.resolve-note {
    margin-right: 5px;
}

.clear-notifications-button {
    font-size: 1.2em;
    margin-left: 5px;
    color: #337ab7;
}

.references-arrow {
    color: white;
    font-size: 125%;
}

.wraparound-button {
    padding: 0px;
    border: 0px;
    background: none;
    display: flex;
    align-items: flex-start;
}

.ok-button {
    color: green;
    font-size: 250%;
    vertical-align: baseline;
    cursor: pointer;
    position: relative;
}

.cancel-button {
    font-size: 250%;
    vertical-align: baseline;
    cursor: pointer;
    position: relative;
}

.image-edit-button {
    font-size: 150%;
    color: #337ab7;
    margin-left: 5px;
}

.image-search-toolbar-icon {
    font-size: 175%;
}

.image-search-toolbar-approval-button {
    font-size: 175%;
    margin-left: 5px;
    margin-right: 5px;
}

.image-delete-button {
    font-size: 225%;
    margin-left: 316px;
}

.image-viewer-close-button {
    font-size: 200%;
    padding: 10px;
}

.image-approval-button {
    font-size: 175%;
    margin-right: 5px;
}

.note-delete-button {
    font-size: 150%;
    color: #337ab7;
    opacity: 0;
}

.note-clone-button {
    font-size: 150%;
    color: #337ab7;
    opacity: 0;
}

.consultant-only-active {
    font-size: 150%;
    color: purple;
    border: 1px solid purple;
}

.sl-zoom-in-timeline-button {
    font-size: 100%;
}

.sl-zoom-out-timeline-button {
    font-size: 100%;
}

.sl-help-icon {
    color: white;
    font-size: 110%;
}

.sl-selection-present {
    background-color: lightgray;
}

.sl-segment-sync-button {
    margin-right: 5px;
}

.center-align-flex {
    flex-direction: column;
    align-items: center;
}

.note-video-stop-button {
    font-size: 220%;
}

.note-video-record-button {
    font-size: 220%;
    color: #953b3b;
}

.note-video-cancel-record-button {
    font-size: 220%;
}

.note-video-pause-recording-button {
    font-size: 220%;
}

.note-video-button {
    margin-right: 10px;
}

.segment-document-ok-button {
    color: green;
}

.segment-document-stop-button,
.segment-document-pause-button,
.segment-document-record-button {
    margin-right: 5px !important;
}

.segment-document-button {
    color: #337ab7;
}

.modal-footer-button {
    font-size: 250%;
}

.star-button {
    display: inline;
    position: relative;
    width: 10px;
}

.star-icon {
    display: inline;
    color: darkorange;
    font-size: 50%;
    padding-left: 2px;
    padding-right: 2px;
    position: absolute;
    top: -10px; /* I could not figure out a better way to vertically center this icon */
    left: 0px;
}

.help-link {
    font-size: 150%;
    cursor: pointer;
    margin-left: 10px;
    color: #337ab7;
}

.modal-title .help-link {
    font-size: 100%;
}

.note-audio-record-button {
    margin: auto;
}

.sl-adjust-current-time-buttons {
    display: flex;
    justify-content: center;
}

.split-button {
    padding: 5px;
}

.close-pane-button {
    font-size: 150%;
}

.styled-tooltip {
    max-width: 200px;
}

.go-to-end-button {
    font-size: 150%;
}

.go-to-start-button {
    font-size: 150%;
    padding-right: 10px;
}
