.drop-target-area {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: lightgray;
    opacity: 0.9;
    display: flex;
    z-index: 100;
}

.drop-target-center-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.drop-target {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.ignore-pointer-events {
  pointer-events: none;
}

.full-size {
  width: 100%;
  height: 100%;
}