.project-setup-modal__footer {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}

.project-setup-modal__previous-button-wrapper {
    display: flex;
}

.project-setup-modal__next-button-wrapper {
    display: flex;
    justify-content: flex-end;
}

.project-setup-modal__footer-icon {
    font-size: 150%;
}

.project-setup-modal__footer-button {
    display: flex;
    align-items: center;
    gap: 5px;
}

.project-setup-modal__passage-add-wrapper {
    padding-top: 1rem;
}

.project-setup-modal__passage-divide-question {
    margin-bottom: 1rem;
}

.project-setup-modal__step-progress {
    display: inline-block;
    white-space: nowrap;
}
