h4 {
    padding-top: 1rem;
}

.project-heading-row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.project-references-empty-book-name {
    color: darkgray;
}

.project-references-populate-book-names-control {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.project-references-fill-button {
    font-weight: normal;
}

.font-weight-normal {
    font-weight: normal;
}

.project-references-book-row {
    height: 35px;
    border-bottom: 1px solid lightgray;
}

.project-references-table-header {
    border-bottom: 2px solid lightgray;
}

.project-references-table-item {
    padding-left: 10px;
}

.book-name-buttons {
    margin-left: 5px;
}

.book-name-row-not-editing {
    display: flex;
    align-items: center;
}

.book-name-edit-button {
    color: #337ab7;
    font-size: 130%;
    opacity: 0;
}

.book-name-row-not-editing:hover .book-name-edit-button {
    opacity: 1;
    transition-duration: 0.5s;
}

.book-download-button {
    color: #337ab7;
    font-size: 130%;
}

.book-download-button.fa-rotate {
    color: green;
}

.download-progress-icon {
    padding: 0px;
}

.download-bible-audio-success-icon {
    display: block;
    width: 18px;
    height: 20px;
    margin: auto;
}

.download-bible-text-success-icon {
    font-size: 130%;
}

span.bible-abbreviation {
    direction: ltr; /* always ltr */
    display: inline-block;
}

.small-success-icon {
    color: green;
    font-size: 130%;
}

.small-error-icon {
    color: #953b3b;
    font-size: 130%;
}

.resource-download-button-and-label {
    display: flex;
    align-items: center;
    gap: 5px;
}

.project-resource-table-row {
    height: 40px;
}

.project-resource-table-cell {
    vertical-align: middle !important;
}

.project-resource-table-cell-content {
    text-align: center;
}

.project-preferences-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.note-marker-type-edit-button,
.note-marker-type-edit-button:focus,
.note-marker-type-edit-button:focus:not(:focus-visible) {
    display: flex;
    align-items: center;
    padding: 5px;
    outline: 1px solid lightgrey;
    border-radius: 4px;
}

.note-marker-color-icon {
    font-size: 150%;
}

.note-marker-color-modal-buttons {
    display: flex;
}

.note-type-color-picker {
    display: flex;
    align-items: center;
    gap: 10px;
}

.note-type-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.note-type-header-marker {
    font-size: 75%;
}

.team-preference-option {
    display: flex;
    margin-bottom: 10px;
}

.project-description-wrapper {
    align-items: flex-start;
}

.flex-centered {
    display: flex;
    align-items: center;
}

.vertically-centered-text {
    vertical-align: middle !important;
}

.project-display-name {
    padding: 5px;
}

.project-display-name-editor {
    display: flex;
    margin-bottom: 10px;
}

.project-region-editor {
    margin-top: 20px;
    display: flex;
}

.project-text-text {
    resize: none;
}

.project-settings-icon,
.project-preferences-icon {
    font-size: 150%;
    color: #337ab7;
}

.project-settings-icon.fa-download,
.project-settings-icon.fa-upload {
    padding-right: 0;
    padding-left: 1px;
}

.project-settings-members-icon {
    padding: 5px;
}

.project-preferences-edit-button {
    font-size: 150%;
    color: lightgrey;
}

.project-preferences-tab:hover .project-preferences-edit-button {
    color: #337ab7;
    transition-duration: 0.5s;
}

.project-text__editing-area {
    height: 90px;
}

.project-text__outer-wrapper {
    width: 500px;
}

.project-preferences-switch {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.recording-layout-preferences {
    display: flex;
    width: 300px;
}

.override-recording-layout-container {
    display: flex;
    align-items: flex-start;
}

.sortable-container {
    transition: background-color 350ms ease;
    background-color: rgba(246, 246, 246, 1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 10px;
    margin: 10px;
    flex: 1;
}

.sortable-container-horizontal {
    display: flex;
}

.sortable-container-item {
    background-color: white;
    padding: 10px;
    margin: 10px 0;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.sortable-container-item.horizontal-sortable-container-item {
    margin: 0px 5px;
}

.sortable-container-item span.fa-grip-vertical {
    padding: 10px;
}

.sortable-container-item span.fa-grip-vertical:hover {
    background-color: rgba(246, 246, 246, 1);
}

.sortable-container-item .sortable-container-item-switch {
    padding-top: 5px;
}

.backup-project-icon {
    font-size: 150%;
    color: #337ab7;
}

.backup-project-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.book-name-table-head {
    position: sticky;
    top: 0;
    background-color: lightgray;
    z-index: 2;
}

.book-name-table-head th {
    background-color: unset;
}

.project-resources-icon {
    width: 32px;
    height: 32px;
    padding: 5px;
    display: flex;
}

.project-resources-icon svg {
    fill: #337ab7;
}

.project-settings-tab__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -4px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.note-marker-colors-label,
.note-marker-type-edit-button,
.note-marker-type-edit-button:focus,
.note-marker-type-edit-button:focus:not(:focus-visible),
.project-preferences-icon,
.team-preference-option-label {
    margin-right: 10px;
}

body[dir='rtl'] .note-marker-colors-label,
body[dir='rtl'] .note-marker-type-edit-button,
body[dir='rtl'] .note-marker-type-edit-button:focus,
body[dir='rtl'] .note-marker-type-edit-button:focus:not(:focus-visible),
body[dir='rtl'] .project-preferences-icon,
body[dir='rtl'] .team-preference-option-label {
    margin-right: 0;
    margin-left: 10px;
}

.countdown-input {
    width: 40px;
}

.project-preferences-gain-control-container {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;
}

.project-preferences-gain-control {
    margin-top: 6px;
}

.project-preferences-tab label {
    margin-left: 0;
    margin-right: 5px;
}

body[dir='rtl'] .project-preferences-tab label {
    margin-left: 5px;
    margin-right: 0;
}

.video-settings-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.video-setting-option {
    margin-right: 10px;
}

.bibles-table-container {
    max-height: 500px;
    overflow: auto;
}

.bibles-table-search-input__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
}

.bibles-table-search-input__icon {
    font-size: 150%;
}

.bibles-table__bible-language-col {
    width: 125px;
}

.bibles-table__show-hide-col {
    width: 50px;
}
