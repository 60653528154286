.er-divs {
    font-size: 120%;
}

.er {
    font-size: 120%;
}

.er-d {
    font-style: italic;
    padding: 1em 0;
}

.er-b {
    height: 20px;
}

.er-ms,
.er-ms1,
.er-ms2,
.er-ms3,
.er-ms4,
.er-ms5,
.er-ms6,
.er-s,
.er-s1,
.er-s2,
.er-s3,
.er-s4,
.er-s5,
.er-s6 {
    padding: 0.25em 0;
    font-weight: bold;
    text-align: center;
}

.er-p {
    text-indent: 1em;
    margin-bottom: 3px;
}

.er-q1 {
    margin-left: 1em;
}

body .er-divs[dir='rtl'] .er-q1 {
    margin-left: 0px;
    margin-right: 1em;
}

.er-q2 {
    margin-left: 2em;
}

body .er-divs[dir='rtl'] .er-q2 {
    margin-left: 0px;
    margin-right: 2em;
}

.er-qa {
    text-align: center;
    font-weight: bold;
    font-style: italic;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.er-qc {
    text-align: center;
}

.er-qr {
    text-align: right;
    margin: 0 1em;
}

body .er-divs[dir='rtl'] .er-qr {
    text-align: left;
}

.er-r {
    font-style: italic;
    text-align: center;
}

.er-sp {
    font-style: italic;
    padding: 0.25em 0;
}

.er-v {
    vertical-align: 30%;
    font-size: 70%;
    font-weight: bold;
    padding-right: 2px;
}

.er-divs[dir='rtl'] .er-v {
    padding-left: 2px;
    padding-right: 0px;
}

.er:hover .er-wg {
    color: #337ab7;
}

.er-wg:hover {
    cursor: pointer;
    text-decoration: underline;
}

.er-image {
    font-size: 13px;
    text-indent: 0;
    padding-right: 3px;
    vertical-align: 1px;
    color: rgb(160, 160, 160);
    cursor: pointer;
}

.er-divs[dir='rtl'] .er-image {
    padding-left: 3px;
    padding-right: 0px;
}

.er-image-display {
    width: 100%;
    border: solid lightgrey 2px;
    margin-bottom: 20px;
}

.er-images-div {
    overflow-y: auto;
    height: 80vh;
}

.er-references {
    margin-top: 10px;
}

.er-reference-link {
    color: grey;
}

.er-reference-link-enabled {
    color: #337ab7;
    font-weight: 600;
    font-size: 105%;
}

.er-lemma-title {
    font-size: 200%;
    font-weight: bold;
    margin-right: 5px;
}

.er-divs[dir='rtl'] .er-lemma-title {
    margin-left: 5px;
    margin-right: 0px;
}

.er-header {
    margin-top: 5px;
    display: flex;
}

.er-glosses {
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.er-divs[dir='rtl'] .er-glosses {
    margin-left: 0px;
    margin-right: 10px;
}

.er-notes {
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.er-divs[dir='rtl'] .er-notes {
    margin-left: 0px;
    margin-right: 10px;
}

.er-label {
    color: grey;
    font-style: italic;
    margin-right: 5px;
    flex: 0 1 100px;
    text-align: right;
}

.er-divs[dir='rtl'] .er-label {
    margin-left: 5px;
    margin-right: 0px;
}

.er-explanation {
    font-style: italic;
}

.er-record-button {
    color: #953b3b;
}

.er-record-sense {
    color: #953b3b;
    font-size: 200%;
}

.er-star {
    font-size: 200%;
    color: darkorange;
}

.er-star-button {
    margin-right: auto;
}

body[dir='rtl'] .er-star-button,
.er-divs[dir='rtl'] .er-star-button {
    margin-left: auto;
    margin-right: 0px;
}

.er-rendering-buttons {
    margin: auto;
}

.er-term-modal-body {
    height: 500px;
    overflow-y: auto;
}

.er-viewer-toolbar {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
    align-items: center;
    gap: 10px 10px;
}

.er-toolbar {
    margin-bottom: 5px;
    display: flex;
    padding: 5px;
}

.er-toolbar-first-group {
    display: flex;
    align-items: center;
    flex: 0 1 33.3333%;
}

.er-toolbar-last-group {
    display: flex;
    align-items: center;
    flex: 0 1 33.3333%;
    justify-content: center;
}

.er-toolbar-last-group .delete-video-button {
    font-size: 2.5em;
}

.er-viewer {
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.er-viewer-body {
    overflow-y: auto;
}

.project-reference-video-player-title {
    font-size: 140%;
    font-weight: bold;
    margin: 5px 0px 7px 5px;
    border-bottom: solid lightgrey 1px;
    padding-bottom: 2px;
}

.project-reference-video-player-content {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 98%;
}

.sign-player {
    display: flex;
    height: 98%;
}

.sign-player-video {
    width: 100%;
    height: 100%;
}

.sign-player-video-area {
    display: flex;
    flex-grow: 1;
}

.sign-player-video-content {
    flex-grow: 1;
}

.er-definition-link {
    color: #337ab7;
}

.er-definition-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.er-sign-video-present {
    color: #953b3b;
    font-weight: bold;
}

.project-reference-video-player-player {
    display: flex;
    flex-grow: 1;
    min-height: 0;
}

.er-new-chapter {
    margin-left: 0px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 110%;
    text-indent: 0px;
}
.er-divs[dir='rtl'] .er-new-chapter {
    margin-right: 0px;
}

.er-resources-select {
    text-overflow: ellipsis;
    margin-right: 0;
    max-width: 225px;
}

.er-resources-select.pull-right {
    margin-left: auto;
}

body[dir='rtl'] .er-resources-select.pull-right {
    margin-left: 0px;
    margin-right: auto;
}

.er-resources-select .sl-dropdown {
    direction: ltr; /* always ltr */
}

.bhs-text {
    font-family: 'SBLHebrewW', sans-serif;
}

.ubsgnt5-text {
    font-family: 'GalatiaSILW', sans-serif;
}

.play-gloss-button {
    font-size: 100%;
    color: #337ab7;
}

.er-play-sense {
    font-size: 200%;
}

.er-delete-sense-rendering {
    font-size: 200%;
}

.er-delete-sense-rendering-button {
    margin-left: auto;
}

body[dir='rtl'] .er-delete-sense-rendering-button {
    margin-left: 0px;
    margin-right: auto;
}

.er-project-glosses-edit-button {
    color: #337ab7;
    font-size: 150%;
}

.er-play-verse-button {
    font-size: 105%;
    color: #337ab7;
}

.er-play-gloss-button {
    font-size: 105%;
    color: #337ab7;
    padding: 0px 2px;
}

.er-play-gloss-button__button {
    display: inline;
}

.er-highlighted-sense {
    border: 5px solid #337ab7;
    border-radius: 10px;
    padding: 10px;
}

.er-term-found {
    color: green;
    font-size: 100%;
    margin-right: 5px;
}

.er-divs[dir='rtl'] .er-term-found {
    margin-left: 5px;
    margin-right: 0px;
}

.er-gloss-text-editor {
    border: solid lightgray 1px;
    padding: 8px;
    flex-grow: 1;
    overflow-y: auto;
    resize: none;
    margin-right: 5px;
}

.er-divs[dir='rtl'] .er-gloss-text-editor {
    margin-left: 5px;
    margin-right: 0px;
}

.er-glosses-text-editor {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.er-glosses-ok-button {
    font-size: 150%;
    color: green;
}

.er-glosses-cancel-button {
    font-size: 150%;
}

.er-notes-editor {
    border: solid lightgray 1px;
    padding: 8px;
    flex-grow: 1;
    height: 100px;
    overflow-y: auto;
    resize: none;
    margin-right: 5px;
}

.er-divs[dir='rtl'] .er-notes-editor {
    margin-left: 5px;
    margin-right: 0px;
}

.er-dropdown-item {
    padding-left: 27px;
}

.er-dropdown-item-focused {
    background-color: #e8e8e8;
}

.er-dropdown-item-selected {
    background-color: #337ab7;
    color: white;
}

body[dir='rtl'] .er-dropdown-item {
    padding-left: 0px;
    padding-right: 27px;
}

.er-bible-header {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.er-bible-sub-header {
    font-size: 10pt;
}

body[dir='rtl'] .er-bible-sub-header {
    text-align: right;
}

.published-audio-separator {
    border-bottom: 1px solid lightgray;
}

.er-has-audio-icon {
    width: 15px;
    height: 15px;
    margin: 0 4px;
}

.er-highlight {
    background-color: #ffffc5;
}

.sign-player-audio-container {
    display: flex;
    gap: 10px;
}

.sign-player-audio-player-wrapper {
    flex-grow: 1;
}

.sign-player-pane-close-button {
    padding: 0px;
}

/* alwasys ltr */
.er-bible-copyright {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    direction: ltr;
}

.er-bible-copyright div {
    text-align: center;
    color: #666666;
    font-size: 85%;
}

.er-bible-copyright div p {
    margin: 0px;
}

.er-bible-copyright div a {
    color: #666666;
    text-decoration: none;
}

/* consistent with enhanced links */
.er-bible-copyright a:hover {
    color: #337ab7;
    cursor: pointer;
    text-decoration: underline;
}

/* span display classes */
.er-it {
    font-style: italic;
}

div.er-li,
div.er-li1 {
    margin-left: 1em;
}

.er-divs[dir='rtl'] div.er-li,
.er-divs[dir='rtl'] div.er-li1 {
    margin-left: 0em;
    margin-right: 1em;
}

div.er-li2 {
    margin-left: 2em;
}

.er-divs[dir='rtl'] div.er-li2 {
    margin-left: 0em;
    margin-right: 2em;
}

span.er-litl:before {
    content: '...';
    margin: 0 0.25em;
}

span.er-litl {
    margin-left: auto;
}

.er-divs[dir='rtl'] span.er-litl {
    margin-left: 0em;
    margin-right: auto;
}

.er-nd {
    font-variant: small-caps;
}

.er-qs {
    display: block;
    text-align: right;
    font-style: italic;
}

.er-divs[dir='rtl'] .er-qs {
    text-align: left;
}

.er-sc {
    font-variant: small-caps;
}

.er-sig {
    font-style: italic;
}

.er-tl {
    font-style: italic;
}

.er-tr {
    display: table-row;
}

.er-tr > span > span[class^='er-th'] {
    font-style: italic;
}

.er-tr > span > span[class^='er-th'],
.er-tr > span > span[class^='er-tc'] {
    padding-left: 1em;
}

.er-tr > span > span.er-th1 ~ span.er-th1,
.er-tr > span > span.er-th2 ~ span.er-th2,
.er-tr > span > span.er-th3 ~ span.er-th3,
.er-tr > span > span.er-tc1 ~ span.er-tc1,
.er-tr > span > span.er-tc2 ~ span.er-tc2,
.er-tr > span > span.er-tc3 ~ span.er-tc3,
.er-tr > span > span.er-tcr1 ~ span.er-tcr1,
.er-tr > span > span.er-tcr2 ~ span.er-tcr2,
.er-tr > span > span.er-tcr3 ~ span.er-tcr3 {
    padding-left: 0;
}

.er-divs[dir='rtl'] .er-tr > span > span[class^='er-th'],
.er-divs[dir='rtl'] .er-tr > span > span[class^='er-tc'] {
    padding-right: 1em;
}

.er-divs[dir='rtl'] .er-tr > span > span.er-th1 ~ span.er-th1,
.er-divs[dir='rtl'] .er-tr > span > span.er-th2 ~ span.er-th2,
.er-divs[dir='rtl'] .er-tr > span > span.er-th3 ~ span.er-th3,
.er-divs[dir='rtl'] .er-tr > span > span.er-tc1 ~ span.er-tc1,
.er-divs[dir='rtl'] .er-tr > span > span.er-tc2 ~ span.er-tc2,
.er-divs[dir='rtl'] .er-tr > span > span.er-tc3 ~ span.er-tc3,
.er-divs[dir='rtl'] .er-tr > span > span.er-tcr1 ~ span.er-tcr1,
.er-divs[dir='rtl'] .er-tr > span > span.er-tcr2 ~ span.er-tcr2,
.er-divs[dir='rtl'] .er-tr > span > span.er-tcr3 ~ span.er-tcr3 {
    padding-right: 0;
}

.er-wj {
    color: #c00;
}
