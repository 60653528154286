.timeline-slider {
    position: relative;
    width: auto;
    height: 30px;
    border: 1px solid lightgrey;
}

.timeline-rail-outer {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 5px;
    height: 15px;
}

.timeline-rail {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 8px;
    height: 3px;
    background-color: black;
}


.marker-handle {
    position: absolute;
    margin-top: -6.5px;
    text-align: center;
    font-size: 2.2em;
}

.note-handle {
    margin-top: 0px;
    margin-left: -8px;
    z-index: 1;
    font-size: 1.5em;
}

.fa-caret-left, .fa-caret-right {
    width: 11px;
}

.arrow-handle {
    z-index: 2;
    position: absolute;
}

.time-cursor-handle {
    z-index: 2;
    position: absolute;
    margin-top: 7.5px;
    border-left: 3px solid black;
    height: 15px;
}

.note-marker-time-adjusters {
    display: flex;
    justify-content: space-between;
}