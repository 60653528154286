.key-term-marker-delete-button {
  font-size: 250%;
}

.new-term-marker-error {
  color: red;
}

.key-term-marker-arrow-button {
  font-size: 75%;
}

.key-term-marker-help {
  font-style: italic;
}

.biblical-term-marker-warning {
  margin-bottom: 10px;
}

.biblical-term-marker-editor-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.biblical-term-marker-editor-footer {
  justify-content: space-between;
}