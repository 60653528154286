.select-prefix__option--is-focused {
    background-color: #e8e8e8 !important;
}

.select-prefix__option--is-selected {
    background-color: #337ab7 !important;
}

.select-prefix__group-heading {
    font-size: 14px !important;
    text-transform: none !important;
}
