.dropdown-menu {
    font-size: 100%;
}

.form-label {
    font-weight: 700;
}

svg {
    vertical-align: unset;
}

.modal-footer .button-row {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.h5,
h5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.btn-primary {
    --bs-btn-bg: #337ab7;
    --bs-btn-border-color: #337ab7;
    --bs-btn-hover-bg: #256298;
    --bs-btn-hover-border-color: #256298;
    --bs-btn-active-bg: #1e4e7e;
    --bs-btn-active-border-color: #1e4e7e;
    --bs-btn-disabled-bg: #6a9bc6;
    --bs-btn-disabled-border-color: #6a9bc6;
}
