.note-container {
    display: flex;
    min-height: 350px;
    width: 100%;
}

.note-item-main {
    display: flex;
}

.note-item-controls {
    display: flex;
    align-items: center;
}

.note-marker-dropdown {
    display: inline-block;
    margin: 0 5px;
    background-image: none;
}

.note-marker-placeholder {
    margin: 0 5px;
    padding: 5px;
}

a.note-marker-dropdown-item {
    margin: 0;
    padding: 3px 6px;
    background-image: none;
}

.note-marker-dropdown-marker {
    margin: 0 5px;
    font-size: 45%;
}

/* .note-play-cell {
  padding-top: 15px;
  vertical-align: top;
  width: 160px;
} */

.note-text {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 8px;
    white-space: normal;
    display: inline-block;
    width: 100%;
    overflow-wrap: break-word; /* Ensure words break at the boundaries of the container instead of overflowing */
}

.note-text-paragraph {
    margin-bottom: 5px;
}

/* .note-play-link {
  padding-right: 15px;
} */

/* .note-item-buttons {
  vertical-align: middle;
  padding-top: 15px;
} */

/* .note-item-button {
  margin-left: 10px;
} */

/* .note-record-control {
  text-align: center;
} */

/* .note-message {
  color: lightgrey;
  padding-top: 5px;
} */

.note-record-button {
    text-align: center;
    flex-basis: 100px;
}

.note-record-button-video {
    cursor: pointer;
    color: #a94442;
}

.note-record-button-write {
    cursor: pointer;
}

.note-text-editor {
    min-height: 50px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.note-text-editor-editor {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 8px;
    min-height: 60px;
}

.note-text-editor-buttons {
    padding-top: 12px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
}

.note-text-editor-ok {
    width: 70px;
    margin-left: auto;
}

body[dir='rtl'] .note-text-editor-ok {
    margin-left: 0px;
    margin-right: auto;
}

.note-video {
    display: block;
    height: 115px;
}

.note-image {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

/* .image-view {
  display: flex;
  width: 800px;
  height: 420px;
}

.image-view-image-container {
  flex-grow: 1;
  background-color: lightgray;
}

.image-view-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-view-image-placeholder {
  width: 100%;
  height: 100%;
}

.image-view-close-button {
  font-size: 1.25em;
  position: relative;
  left: 8px;
  top: -8px;
} */

/* .note-content-cell {
  padding-top: 15px;
} */

.note-item-notification {
    font-size: 150%;
}

.note-item-edit-button {
    font-size: 100%;
    color: #337ab7;
    opacity: 0;
}

.note-description:hover .note-item-edit-button {
    opacity: 1;
    transition-duration: 0.5s;
}

.note-item-download-button {
    font-size: 150%;
    color: #337ab7;
    opacity: 0;
    padding-left: 6px;
}

.note-item:hover .note-item-download-button {
    opacity: 1;
    transition-duration: 0.5s;
}

.note-item:hover .note-item-edit-button {
    opacity: 1;
    transition-duration: 1s;
}

.note-item:hover .note-delete-button {
    opacity: 1;
    transition-duration: 1s;
}

.note-item:hover .note-clone-button {
    opacity: 1;
    transition-duration: 1s;
}

.note-patch-video-button {
    opacity: 0;
    font-size: 220%;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 4px;
    color: #da8383;
}

.note-item:hover .note-patch-video-button {
    opacity: 1;
    transition-duration: 1s;
}

.note-item-player {
    display: flex;
}

.note-item-player-video-area {
    display: flex;
    flex-grow: 1;
    border: 1px solid lightgray;
}

.note-item-player-video {
    width: 100%;
    height: 100%;
}

.note-item-player-video-content {
    display: flex;
    flex-grow: 1;
}

.note-messages {
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
}

.note-add-buttons {
    margin-top: 5px;
    display: flex;
    gap: 10px;
}

.note-navigator {
    display: flex;
    align-items: center;
}

.note-dialog-close-button {
    font-size: 175%;
}

.note-header-right {
    flex-grow: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.note-header {
    width: 100%;
}

@media screen and (max-width: 991px) {
    .note-header {
        display: flex;
    }
}

@media screen and (min-width: 992px) {
    .note-header {
        display: flex;
        align-items: center;
    }
}

.note-description {
    display: flex;
    font-size: 160%;
}

.note-description-wrapper {
    display: flex;
}

.note-main-header-buttons {
    display: flex;
}

.note-main-header {
    margin-bottom: 18px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media screen and (max-width: 991px) {
    .note-header-button-row {
        display: flex;
        align-items: center;
    }

    .note-header-left {
        display: flex;
        flex-direction: column;
    }

    .note-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .note-item-header {
        order: 0;
    }

    .note-item-controls {
        display: flex;
        align-items: center;
        order: 1;
    }

    .note-item-main {
        order: 2;
    }
}

@media screen and (max-width: 550px) {
    .timeline-slider {
        border: none !important;
    }
}

@media screen and (min-width: 992px) {
    .note-item {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
    }

    .note-item-header {
        flex: 0 0 180px;
        margin: 0 5px;
    }

    .note-item-main {
        order: 1;
    }

    .note-item-controls {
        padding-left: 10px;
        align-items: center;
        display: flex;
        order: 2;
    }
}

.note-citation {
    padding: 6px;
}

.note-image-thumbnail {
    height: 140px;
    width: 140px;
}

.note-item-player-video-placeholder {
    height: 100%;
    width: 100%;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 550px) {
    .note-image-thumbnail-3x {
        width: 100%;
    }
}

@media screen and (min-width: 551px) {
    .note-image-thumbnail-3x {
        width: 100%;
    }
}

@media screen and (min-width: 992px) {
    .note-image-thumbnail-3x {
        max-width: 600px;
    }
}

.note-item-creation-date {
    padding-left: 5px;
}

.note-text-editor__editing-area {
    height: 300px;
}

/* .note-text-editor__inner-wrapper {
  flex: 1 1 400px;
}

.note-text-editor__outer-wrapper {
  display: flex;
  flex-grow: 1;
} */

.note-main-body {
    flex-grow: 1;
    width: 100%;
}

.note-dialog-footer {
    display: flex;
    width: 100%;
}

.close-note-dialog {
    margin-left: auto;
}

body[dir='rtl'] .close-note-dialog {
    margin-left: 0px;
    margin-right: auto;
}

.video-recording-toolbar {
    display: flex;
}

.note-video-recorder {
    display: flex;
    flex-direction: column;
}

.note-audio-recorder {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.note-audio-waveform-visualizer {
    width: 100%;
    height: 45px;
    border: 1px solid lightgrey;
    border-bottom: none;
    display: block; /* Override default inline-block so no margin below canvas */
}

.clipboard-button-wrapper {
    margin-left: auto;
}

body[dir='rtl'] .clipboard-button-wrapper {
    margin-left: 0px;
    margin-right: auto;
}

.note-audio-recorder-waveform-wrapper {
    height: 80px;
    position: relative;
    display: flex;
}

.audio-note-item {
    flex-grow: 1;
}
