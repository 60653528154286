.resources-viewer-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
    align-items: center;
    gap: 10px 10px;
}

.resources-toolbar {
    margin-bottom: 5px;
    display: flex;
    padding: 5px;
}

.resources-toolbar-first-group {
    display: flex;
    align-items: center;
    flex: 0 1 33.3333%;
}

.resources-toolbar-last-group {
    display: flex;
    align-items: center;
    flex: 0 1 33.3333%;
    justify-content: center;
}

.resources-viewer {
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.resource-body {
    overflow-y: auto;
}

.resource-body h1 {
    font-size: 18px;
}

.resource-body .media-title {
    font-size: 1rem;
    font-weight: 500;
    margin: 1rem 0 0.25rem;
}

.resource-body .media-loading {
    font-size: 1.5rem;
    width: 100%;
}

.resource-body .callout {
    border: 1px solid lightgray;
    border-radius: 5px 5px;
    margin: 1rem;
    padding: 0 1rem;
    font-style: italic;
    font-weight: 500;
}

.resource-body p {
    margin: 1rem 0;
}

.resources-select {
    width: 250px;
}

.exegetical-selectors-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

body[dir='ltr'] .resources-dropdown-item {
    padding-left: 0px;
    padding-right: 20px;
}

body[dir='rtl'] .resources-dropdown-item {
    padding-left: 20px;
    padding-right: 0px;
}

.resources-dropdown-title {
    margin-right: 10px;
}

body[dir='ltr'] .resources-dropdown-title {
    margin-left: 10px;
    margin-right: 0px;
}

.resource-audio-player {
    margin: 10px 0;
}

.resource-audio-separator {
    border-bottom: 1px solid lightgray;
}
