.task-container {
    border: 1px solid lightgrey;
    border-radius: 2px;
    padding: 4px;
    margin-bottom: 4px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.task-notifications {
    display: flex;
    justify-content: flex-end;
}

.task-title {
    display: flex;
    align-items: center;
}

.task-title > div {
    display: contents;
}

.task-title-text {
    margin: 0 4px;
    overflow-wrap: anywhere;
}

.task-references {
    display: flex;
    align-items: center;
    opacity: 0;
}

.visible {
    opacity: 1;
    transition-duration: 0.5s;
}
