.image-expand-button {
    font-size: 175%;
}

.images-tab-loading-icon {
    width: 100%;
    height: 100%;
}

.task-references-icon {
    font-size: 120%;
}

.drop-target-icon {
    font-size: 750%;
}

.ok-icon {
    color: green;
}

.initialization-message-icon {
    margin-right: 10px;
}

body[dir='rtl'] .initialization-message-icon {
    margin-right: 0;
    margin-left: 10px;
}

.right-pane-tab-icon {
    font-size: 150%;
}

.compare-draft-left-icon {
    padding-right: 2px;
}

body[dir='rtl'] .compare-draft-left-icon {
    padding-right: 0;
    padding-left: 2px;
}

.compare-draft-right-icon {
    padding-left: 2px;
}

body[dir='rtl'] .compare-draft-right-icon {
    padding-left: 0;
    padding-right: 2px;
}

.sl-dbs-play-icon {
    color: blue;
}

.passage-assignee-icon {
    font-size: 120%;
}

.sl-show-passage-segment {
    padding: 5px;
    height: 32px;
    position: relative;
    top: 7px;
}

.sl-download-full-video-icon {
    font-size: 150%;
    color: #337ab7;
    margin-right: 10px;
}

body[dir='rtl'] .sl-download-full-video-icon {
    margin-right: 0;
    margin-left: 10px;
}

.detach-icon {
    font-size: 150%;
    margin-right: 10px;
}

body[dir='rtl'] .detach-icon {
    margin-right: 0;
    margin-left: 10px;
}

.main-video-clipboard-icon {
    font-size: 150%;
    margin-right: 10px;
}

body[dir='rtl'] .main-video-clipboard-icon {
    margin-right: 0;
    margin-left: 10px;
}

.main-video-see-more-icon {
    font-size: 120%;
}

.audio-loading-icon {
    font-size: 150%;
    margin-right: 10px;
}

body[dir='rtl'] .audio-loading-icon {
    margin-right: 0;
    margin-left: 10px;
}

.note-audio-record-icon {
    display: block;
    fill: #a94442;
    width: 3em; /* same as fa-3x class uses */
    height: 3em; /* same as fa-3x class uses */
}

.ban-icon {
    color: tomato;
}

.passage-recording-loading-icon {
    margin-right: 10px;
}

body[dir='rtl'] .passage-recording-loading-icon {
    margin-right: 0;
    margin-left: 10px;
}

.scissors-icon {
    width: 30px;
    height: 30px;
}

.passage-transcription-icon {
    width: 32px;
    height: 32px;
    padding: 5px;
    display: flex;
}
