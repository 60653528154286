.audio-player-wrapper {
    height: 54px; /* This is how tall audio elements are in Chrome */
    display: flex;
    align-items: center;
}

.audio-player {
    width: 100%;
}

.audio-player-placeholder {
    height: 54px; /* This is how tall audio elements are in Chrome */
    border: 1px solid lightgray;
    padding: 5px;
    font-style: italic;
    color: gray;
}

.audio-recorder-visualization-wrapper {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.small-button {
    font-size: 130%;
}

.audio-recorder-record-button {
    color: #953b3b;
    margin: 0;
}

.audio-button-row {
    display: flex;
    align-items: center;
    gap: 10px;
}

audio.default-player {
    width: 100%;
}

video.default-player {
    min-width: 0;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
}

img.default-player {
    height: 400px;
    cursor: pointer;
}

audio.mini-player {
    height: 40px;
    width: 400px;
}

video.mini-player {
    aspect-ratio: 16 / 9;
    height: 120px;
}

img.mini-player {
    height: 120px;
    cursor: pointer;
}

.blink {
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
