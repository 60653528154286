.progress-graph-dropdown-menu {
    background-color: white;
    font-size: 100%;
}

.progress-graph-header {
    margin-bottom: 10px;
}

.progress-graph-header-item {
    display: flex;
    flex-direction: column;
}

.progress-graph-separator {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 10px;
}

.progress-graph-table {
    margin-bottom: 10px;
}