.note-location-editor-content {
    display: flex;
}

/* always ltr */
.audio-note-location-editor-content {
    display: flex;
    direction: ltr;
}

.note-location-editor-video {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid lightgray;
}

.audio-note-location-editor-player {
    display: flex;
    justify-content: center;
    height: 0px;
}

.note-location-editor-player {
    display: flex;
    justify-content: center;
    align-self: center;
}

@media screen and (max-width: 550px) {
    .note-location-editor-player {
        width: 55vw;
        height: 30.9vw;  /* 16:9 */
    }
}

@media screen and (min-width: 551px) {
    .note-location-editor-player {
        width: 400px;
        height: 225px;  /* 16:9 */
    }
}

@media screen and (min-width: 992px) {
    .note-location-editor-player {
        width: 600px;
        height: 337px;  /* 16:9 */
    }
}

.video-timeline-area {
    display: flex;
}

.video-timeline {
    flex-grow: 1;
}

.note-location-editor-speed-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px; /* always ltr */
    height: 100px;
  }