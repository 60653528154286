.modal-back-translation-icon {
    font-size: 150%;
}

.modal-go-back-icon {
    font-size: 250%;
}

.modal-error-message {
    margin-bottom: 15px;
}

.modal-dropdown {
    margin-bottom: 10px;
}

.modal-file-picker {
    display: flex;
    align-items: center;
    gap: 10px;
}

.modal-file-picker-icon {
    font-size: 150%;
    color: #337ab7;
}

.modal-file-list {
    display: flex;
    flex-direction: column;
}

.modal-footer-buttons {
    display: flex;
}

.modal-loading-message {
    margin-left: 10px;
}

.modal-loading-message-parent {
    display: flex;
    align-items: center;
}

.modal-progress-column {
    text-align: right;
}

.modal-results {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.modal-section {
    padding-bottom: 10px;
}

.modal-subheading {
    font-weight: bold;
}

.modal-switch {
    display: flex;
}

.modal-switch label {
    margin-right: 10px;
}

.modal-toggle {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    font-weight: 500;
}

.modal-tab__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -4px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.modal-table-cell {
    vertical-align: middle !important;
}

.modal-table-row {
    height: 40px;
}

.modal-text-editor {
    height: 200px;
    resize: none;
    overflow-y: auto;
    width: 100%;
}

.modal-warning {
    margin-bottom: 10px;
}

.modal-warning-icon {
    color: orange;
    font-size: 130%;
}

body[dir='rtl'] .modal-warning-icon {
    margin-right: 0;
    margin-left: 10px;
}

.modal-warning-message {
    color: orange;
}

.modal-copy-passage-warning-message {
    margin-top: 20px;
}
