:root {
    --avtt-blue: #337ab7;
    --deleted-portion-passage-items-gray: darkgray;
    --portion-passage-menu-items-gray: lightgrey;
    --odd-segment-color: #40b0a6;
    --even-segment-color: #c49a2c;
}

html {
    font-size: 14px;
}

body {
    margin: 0;
    padding: 0;
    --bs-body-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
    --bs-link-color-rgb: 51, 122, 183; /* Same as #337ab7 */
}
a:hover {
    --bs-link-hover-color-rgb: 35, 82, 124; /* Same as #23527c */
}

.is-dev {
    border: dotted 4px red;
}

.hidden-item {
    text-decoration: line-through;
    opacity: 0.5;
}

@font-face {
    font-family: SBLHebrewW;
    src: url(./resources/fonts/SBL_Hbrw.woff) format('woff');
}

@font-face {
    font-family: GalatiaSILW;
    src: url(./resources/fonts/GALSILR.woff) format('woff');
}
