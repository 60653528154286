.video-main {
    padding: 5px 10px;
}

.video-main-bottom {
    padding: 5px 0px;
}

.video-main-bottom-bottom {
    direction: ltr;
    display: flex;
}

.compare-draft-video-main {
    display: flex;
    flex-direction: column;
}

.avtt-video-area {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: 5px;
}

.avtt-audio-area {
    height: 0px;
}

.video-area {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    min-height: 0;
}

.detached-video-player-area {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.audio-waveform-visualizer {
    border: 1px solid lightgrey;
    border-bottom: none;
    width: 100%;
    height: 100%;
    position: absolute;
}

.note-recording-audio-waveform-visualizer {
    border: 1px solid lightgrey;
    width: 100%;
    height: 100%;
    position: absolute;
}

.old-segment-viewer-waveform {
    border: 1px solid lightgrey;
    width: 100%;
    height: 100%;
    position: absolute;
}

.verse-reference-bar {
    border: 1px solid lightgrey;
    width: 100%;
    height: 30px;
}

.video-recorder {
    object-fit: contain;
    height: 100%;
    width: 100%;
    min-height: 0;
}

.video-player {
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

.video-player-video {
    position: relative;
    flex-grow: 1;
}

.video-container {
    flex-grow: 1;
}

.video-player-video-video {
    width: 100%;
    height: 100%;
}

.hidden-video {
    display: none;
}

.video-player-slider {
    flex: 0 1 150px;
}

.video-rate-input-slider {
    height: 100%;
    width: 17px;
}

.u-slider .value {
    background-color: #dddddd !important;
}

.u-slider-y {
    width: 13px !important;
    z-index: 3;
}

.u-slider-y .handle:after {
    width: 17px !important;
    height: 17px !important;
}

[slidertooltip] {
    position: relative;
}

[slidertooltip]::after {
    opacity: 0;
    content: attr(slidertooltip);
    position: absolute;
    left: 50%;
    top: -6px;
    transform: translateX(-50%) translateY(-100%);
    background: #2e8ece;
    text-align: center;
    color: #fff;
    padding: 4px 2px;
    font-size: 12px;
    min-width: 80px;
    border-radius: 5px;
    pointer-events: none;
}

[slidertooltip]:hover::after,
[slidertooltip]:hover::before {
    opacity: 1;
}

[slidertooltip]::before {
    opacity: 0;
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
    z-index: 100;
}

.video-toolbar {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.video-toolbar-left {
    direction: ltr;
    flex-grow: 2;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

body[dir='rtl'] .video-toolbar-left {
    justify-content: right;
}

.sl-adjust-current-time {
    direction: ltr;
    display: flex;
}

.video-timecode {
    flex: 1;
    display: flex;
    align-items: center;
}

.video-positionbar-placeholder {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: right;
}

.passage-video-selector {
    display: inline-block;
    background-image: none;
    border-radius: 0px;
}

.video-toolbar-hamburger-menu {
    display: inline-block;
    background-image: none;
    border-radius: 0px;
    margin: 0 3px;
}

.video-toolbar-hamburger-menu-toggle {
    height: 30px;
    font-size: 10pt;
    padding: 0px 5px;
    background-image: none;
}

.video-toolbar-hamburger-menu-toggle::after {
    display: none !important;
}

.styled-dropdown-select-menu .passage-video-selector-2 {
    padding: 8px 20px;
}

.dropdown-review-project-icon {
    font-size: 100%;
}

.video-toolbar-hamburger-menu-item {
    background-image: none;
}

.scrollable-dropdown {
    overflow-y: auto;
    max-height: 450px;
}

.video-toolbar-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.video-label {
    font-size: 125%;
    position: absolute;
    z-index: 90;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
}

.deleted-video-item {
    color: gray;
}

.deleted-video-item-trash {
    font-size: 120%;
    display: inline;
}

.media-placeholder {
    height: 100%;
    width: 100%;
    background-color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.basic-video-player {
    display: flex;
    min-height: 0;
    flex-grow: 1;
    background-color: lightgray;
}

.basic-video-player-placeholder {
    width: 100%;
    height: 100%;
    background-color: lightgray;
}

.basic-video-player-video {
    width: 100%;
    height: 100%;
}

.media-thumbnail {
    width: 249px;
    height: 140px;
}

.fill-area {
    width: 100%;
    height: 100%;
}

/* always ltr */
.video-player-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

/* always ltr */
.main-video-player-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
    flex: 0;
}

.root-video-player {
    display: flex;
    width: 100%;
    height: 100%;
}

.video-player-container {
    flex-grow: 1;
    display: flex;
}

.draft-video-player {
    display: flex;
    width: 100%;
    height: 100%;
}

.video-message {
    font-style: italic;
    color: #337ab7;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.countdown {
    border: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font: 32px sans-serif;
}

.video-message-child {
    flex-basis: 75%;
}

.video-message-message {
    display: flex;
    align-items: center;
    justify-content: center;
}

.note-recorder-message {
    margin: auto;
    position: relative;
    background: lightgrey;
    height: 15%;
    width: 30%;
    left: -30%;
    top: -35%;
    display: flex;
    align-items: left;
    justify-content: center;
}

.verse-reference-editor {
    border: 1px solid lightgrey;
    display: flex;
    align-items: center;
    padding: 10px;
}

.video-loading-progress-message {
    margin: auto;
    height: 100%;
    width: 100%;
}

.video-loading-message-canvas {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.video-recording-area {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.video-recording-area-message-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
}

.video-recording-countdown-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgb(255, 255, 255);
}

.video-recording-area-message {
    font-size: 32px;
    margin: auto;
}

.compare-draft-video-main-drop-target-container {
    min-height: 0;
    flex-grow: 1;
}

.progress-message {
    display: flex;
    align-items: center;
}

.key-term-marker-button {
    color: darkorange;
}

.passage-video-timestamp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    padding-top: 5px;
}

.passage-video-message {
    padding-top: 10px;
}

/* always ltr */
.visible-timestamp {
    font-size: 90%;
    direction: ltr;
}
