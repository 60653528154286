.passage-passage-segments-editor {
    padding: 10px 10px;
    margin: 20px 20px 0 20px;
    border: solid 4px lightblue;
}

.segment-even {
    border: solid 4px var(--even-segment-color);
}

.segment-even .sl-play-button,
.segment-even .sl-pause-button {
    color: var(--even-segment-color);
}

.segment-odd {
    border: solid 4px var(--odd-segment-color);
}

.segment-odd .sl-play-button,
.segment-odd .sl-pause-button {
    color: var(--odd-segment-color);
}

.segment-references-editor {
    border: solid grey 1px;
    border-radius: 5px;
    padding: 8px;
    margin-top: 5px;
}

.segment-references-error {
    color: red;
}

.segment-approval-not-allowed {
    color: #ababab;
}

.segment-approval-dropdown.styled-dropdown-select,
.old-segment-dropdown.styled-dropdown-select {
    width: 15px;
    padding: 0px;
}

body[dir='rtl'] .segment-approval-dropdown.styled-dropdown-select:after,
body[dir='rtl'] .old-segment-dropdown.styled-dropdown-select:after {
    margin: 0px;
}

.segment-status-icon {
    font-size: 45%;
}

.segment-status-icon-no-label {
    margin-right: 2px;
}

body[dir='rtl'] .segment-status-icon-no-label {
    margin-left: 2px;
    margin-right: 0px;
}

.segment-status-with-label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dash-icon {
    font-size: 120%;
}

.segment-approval-form-container {
    display: flex;
    align-items: center;
    gap: 4px;
}

.segment-dialog {
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    padding: 20px;
    border-color: #337ab7;
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
}

.segment-position-dialog {
    margin-top: 10px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    padding: 20px;
    border-color: #337ab7;
}

.old-segment-viewer-content {
    height: 250px;
    display: flex;
}

.old-segment-viewer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid lightgray;
}

.old-segment-viewer-video {
    width: 100%;
    height: 100%;
    min-height: 0;
    background-color: lightgray;
}

.old-segment-viewer-timeline-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

.old-segment-viewer-timeline {
    height: 20px;
    width: 90%;
    position: relative;
}

.old-segment-viewer-timeline-rail {
    background-color: black;
    height: 3px;
    position: absolute;
    width: 100%;
    margin-top: 8px;
    z-index: 1;
}

.old-segment-viewer-timeline-track {
    position: absolute;
    height: 100%;
    z-index: 2;
}

.old-segment-viewer-timeline-time-cursor {
    z-index: 1;
    position: absolute;
    margin-top: 2px;
    border-left: 3px solid black;
    height: 15px;
}

.old-segment-viewer-bottom {
    height: 45px;
    display: flex;
    align-items: center;
    direction: ltr; /* always ltr */
}

.flex-grow-1 {
    flex-grow: 1;
}

.old-segment-viewer-heading {
    display: flex;
    align-items: center;
}

.segment-dialog-videos {
    display: flex;
    height: 326px;
}

.draggable-label {
    cursor: grab;
}

.draggable-label-dragging {
    cursor: grabbing;
}

.modal-90w {
    width: 90%;
}

.modal-20w {
    width: 330px;
}

.button-row-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.button-row {
    display: flex;
    align-items: center;
}

.audio-recorder-visualization {
    height: 54px;
    position: absolute;
    width: 100%;
    border: 1px solid lightgray;
}

.right-pane-button {
    font-size: 130%;
}

.large-right-pane-button {
    font-size: 200%;
}

.small-segment-document-button {
    font-size: 130%;
}

.large-segment-document-button {
    font-size: 200%;
}

.segment-document-player {
    margin-bottom: 8px;
    width: 100%;
}

.opaque-on-hover {
    opacity: 0;
}

.default-blue-icon {
    color: #337ab7;
}

.segment-resource-text-area {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 8px;
}

.segment-text-editor {
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
}

.segment-text-editor__editor {
    width: 100%;
    border: solid lightgray 1px;
    padding: 8px;
    flex-grow: 1;
    resize: none;
    min-height: 80px;
    white-space: pre-wrap;
}

.segment-text-editor__editor:disabled {
    background-color: white;
}

.segment-resource-text-input {
    flex-grow: 1;
    min-width: 0;
}

.segment-pane-title {
    text-align: center;
}

.back-translation:hover .opaque-on-hover {
    opacity: 1;
    transition-duration: 1s;
}

.segment-transcription {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 8px;
}

.segment-transcription.editing {
    border: 2px solid lightblue;
}

.segment-transcription.editing .multi-select {
    margin-bottom: 8px;
    margin-left: auto;
    min-width: 300px;
}

body[dir='rtl'] .segment-transcription.editing .multi-select {
    margin-right: auto;
    margin-left: 0px;
}

.segment-transcription:hover .opaque-on-hover {
    opacity: 1;
    transition-duration: 1s;
}

.segment-transcription-textarea {
    border: solid lightgray 1px;
    padding: 8px;
    flex-grow: 1;
    height: 100px;
    overflow-y: auto;
    resize: none;
}

.segment-editor-bottom {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
}

.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.order-4 {
    order: 4;
}

/* TODO: Eliminate need to have one sided margins, or use a cpmmon class */
.old-audio-segment-viewer-content,
.old-segment-viewer-content,
.old-segment-viewer-play-button,
.old-segment-viewer-pause-button,
.segment-resource-text-area,
.segment-transcription {
    margin-right: 5px;
}

body[dir='rtl'] .old-audio-segment-viewer-content,
body[dir='rtl'] .old-segment-viewer-content,
body[dir='rtl'] .old-segment-viewer-play-button,
body[dir='rtl'] .old-segment-viewer-pause-button,
body[dir='rtl'] .segment-resource-text-area,
body[dir='rtl'] .segment-transcription {
    margin-right: 0px;
    margin-left: 5px;
}

.old-segment-dropdown {
    margin-right: 10px;
}

body[dir='rtl'] .old-segment-dropdown {
    margin-right: 0px;
    margin-left: 10px;
}
