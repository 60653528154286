.warning-list {
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    gap: 5px;
}

.warning-list-warning-icon {
    font-size: 130%;
    color: orange;
}