.language-selector-option-title {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.language-selector-option-region-script {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.language-selector-option-alternates {
    font-style: italic;
}
