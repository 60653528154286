.portion-name {
    color: var(--avtt-blue);
    display: inline-block;
    padding: 2px 8px;
}

.portion-deleted-name {
    color: var(--deleted-portion-passage-items-gray);
    display: inline-block;
    padding: 2px 8px;
}

.portion-deleted-name:hover {
    color: var(--avtt-blue);
    cursor: pointer;
}

.portion-menu {
    opacity: 0;
    display: flex;
    align-items: center;
}

.portion-portion:hover .portion-menu {
    opacity: 1;
    transition-delay: 0.5s;
    transition-duration: 0.5s;
}

.portion-add-button {
    color: lightgrey;
    font-size: 170%;
    margin: 0 16px;
}

.portion-add-button:hover {
    color: #337ab7;
}

.portion-handle {
    color: var(--avtt-blue);
    font-size: 120%;
    margin: 0px 6px;
    cursor: grab;
}

.portion-handle:hover {
    color: #337ab7;
}

.portion-show-commands {
    color: lightgrey;
}

.portion-show-commands:hover {
    color: #337ab7;
}

@media screen and (any-hover: none), (any-hover: on-demand) {
    .portion-show-commands {
        color: #337ab7;
    }
}

.portion-button {
    margin: 0 5px;
    color: var(--portion-passage-menu-items-gray);
    font-size: 120%;
}

.portion-button:hover {
    color: var(--avtt-blue);
    cursor: pointer;
}

.portion-portion {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.portion-notifications {
    display: inline-block;
}

.portion-notifications i.fa-asterisk {
    font-size: 50%;
    vertical-align: text-top;
}

.portion-references {
    display: inline-block;
    margin-left: 5px;
}

.portion-error {
    color: red;
}

.portions-menu {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.portions-menu-item {
    color: lightgrey;
    font-size: 120% !important;
}

.portions-menu-item:hover {
    color: #337ab7;
}

.portion-info-icon {
    font-size: 140%;
    padding-right: 28px;
    color: #337ab7;
    vertical-align: -2px;
}

.portion-list-buttons {
    display: flex;
    align-items: center;
}
