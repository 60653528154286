.rich-text-editor {
    word-break: break-all;
    flex-basis: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.text-editor-toolbar {
    display: flex;
}

.text-editor-toolbar-left {
    display: flex;
    flex-wrap: wrap;
}

.text-editor-toolbar-right {
    margin-left: auto;
    display: flex;
}

body[dir='rtl'] .text-editor-toolbar-right {
    margin-left: 0px;
    margin-right: auto;
}

button .text-editor-toolbar-button {
    font-size: 100%;
    padding: 0px;
}

button .text-editor-toolbar-right-button {
    font-size: 175%;
}

.text-editor-save-button {
    color: green;
    margin-right: 10px;
}

body[dir='rtl'] .text-editor-save-button {
    margin-right: 0px;
    margin-left: 10px;
}

.rich-text__editing-area strong {
    font-weight: 800;
}