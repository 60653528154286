.plan-editor-task-list {
    padding: 0 10px;
}

.stage-header {
    display: flex;
    margin: 5px 0;
}

.stage-menu {
    opacity: 0;
    display: inline-block;
}

.stage-header:hover .stage-menu {
    opacity: 1;
    transition-duration: .5s;
}